import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Grid, Themed } from "theme-ui"

const Aside = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query Aside {
        site {
          siteMetadata {
            title
            descriptionCustom
          }
        }
      }
    `
  )

  const backgroundColor = props.bgColor ? props.bgColor : `primarydarken`

  return (
    <Grid
      className={`section-aside`}
      sx={{
        backgroundColor:  `${backgroundColor}`,
        gridTemplateColumns: `1fr`,
        gridTemplateRows: [ `auto`,
                            `auto`,
                            `auto`,
                            `1.5rem 1fr`,
                            `1.5rem 1fr`,
                            `1.5rem 1fr`
                          ],
        py: 3,
        px: [3, 3, 3, 3, 3, 4],
        ".title-area": {
          display: [`none`, `none`, `none`, `initial`, `initial`, `initial`],
          fontSize: 2,
          lineHeight: `body`,
          a: {
            color: `primarylight`,
            borderBottom: `none`,
            fontFamily: `body`,
            ":hover": {
              color: `tertiary`,
            },
          },
          p: {
            color: `primarylightest`,
            fontSize: 0,
            margin: 0,
          }
        },
      }}
    >
      <Box className="title-area">
        <Link to="/" rel="bookmark">{data.site.siteMetadata.title}</Link>
        <Themed.p dangerouslySetInnerHTML={{ __html: data.site.siteMetadata.descriptionCustom }} />
      </Box>
      <Box
        className="content-aside"
        sx={{
          variant: 'styles',
          alignSelf: `flex-end`,
          "h2, h3, p, ul, li, label, input": {
            color: `white`,
          },
          "p, ul, li, label, input": {
            variant: `text.base`,
            fontSize: 1,
          },
          p: {
            mb: 2,
          },
        }}
      >
        {props.children}
      </Box>
    </Grid>
  )

}

export default Aside