import React from "react"
import { Flex } from "theme-ui"
import ListInstitutions from "./list-institutions"
import MenuLegal from "./menu-legal"

const Footer = () => {

  return (
    <Flex
      as={`footer`}
      sx={{
        alignItems: `center`,
        backgroundColor: [`secondary`, `secondary`, `secondary`, `transparent`, `transparent`, `transparent`],
        flexDirection: [`column`, `column`, `column`, `row`, `row`, `row`],
        justifyContent: `space-between`,
        paddingTop: [3, 3, 3, 2, 2, 3],
        paddingBottom: [3, 3, 3, 1, 1, 1],
        ".menu-legal" : {
          marginBottom: [2, 2, 2, 0, 0, 0],
          marginLeft: 4,
          marginRight: 3,
        }
      }}
    >
      <MenuLegal />
      <ListInstitutions />
    </Flex>
  )

}

export default Footer