import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Flex, Themed } from "theme-ui"
import { lighten } from "@theme-ui/color"
import getSlug from "speakingurl"

const MenuLegal = () => {

  const data = useStaticQuery(
    graphql`
      query MenuLegal{
        dataJson(slug: {eq: "menu-legal"}) {
          fragment_1
          fragment_2
        }
      }
    `
  )

  const dataFragments = data.dataJson
  const slugFragment1 = getSlug(dataFragments.fragment_1)

  return(
    <Box
      as='div'
      className="menu-legal"
      sx={{
        ul: {
          margin: 0,
          justifyContent: `flex-end`,
          padding: 0,
        },
        li: {
          listStyle: `none`,
          margin: 0,
          "+ li": {
            marginLeft: 1,
          }
        },
        a: {
          color: lighten(`primary`, 0.4),
          borderBottom: `none`,
          fontSize: 0,
          ":hover": {
            fill: `tertiary`,
            borderBottom: `none`,
          },
        },
      }}
    >
      <Box
        as="nav"
        className="content-menu"
        aria-label="Navigation"
      >
        <Flex
          as="ul"
          className="menu-items"
        >
          <Themed.li>
            <Link
              to={`/${slugFragment1}`}
              rel="bookmark"
            >
              {dataFragments.fragment_1}
            </Link>
          </Themed.li>
        </Flex>
      </Box>
    </Box>
  )
}

export default MenuLegal