import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Button, Flex } from "theme-ui"
import MainMenu from "./menu-main"
import MainMenuDesktop from "./menu-main-desktop"
import { useMediaQuery } from 'react-responsive'
import { lighten } from "@theme-ui/color"

const Header = ( props ) => {

  /*
   * Tengo dos main menus, por que tengo que darle estado al main menu mobile
   * Si tengo un solo main menu, no podría darle estado
   * useMediaQuery solo funciona cuando cargo dos componentes diferentes
   * Fijate que uso useMediaQuery de forma diferente a como lo declaro en el map.js
   */

  const data = useStaticQuery(
    graphql`
      query Header {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 })
  const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 })
  const isFrontPage = ( props.page === 'frontpage' ) ? true : false
  const bg = props.bgcolor ? props.bgcolor : `transparent`
  const [isShown, setIsShown] = useState(false)

  function toggle(e) {
    e.preventDefault()
    if(isShown === false ){
      setIsShown(true)
    }else {
      setIsShown(false)
    }
  }

  return (
    <Box
      as={`header`}
      sx={{
        backgroundColor: [`secondary`, `secondary`, `secondary`, `${bg}`, `${bg}`, `${bg}`],
        height: [`100%`, `100%`, `100%`, `auto`, `auto`, `auto`],
        ".container": {
          alignItems: `center`,
          justifyContent: `space-between`,
          padding: [3, 3, 3, `auto`, `auto`, `auto`],
        },
        ".site-title-mobile": {
          color: lighten(`primary`, 0.2),
          borderBottom: `none`,
          fontFamily: `body`,
          fontSize: 2,
          lineHeight: `body`,
          textDecoration: `none`,
        },
      }}
    >
      {isTabletOrMobileDevice &&
        <Flex className={`container`}>
          <Link to="/" className="site-title-mobile" rel="bookmark">{data.site.siteMetadata.title}</Link>
          <Button
            title='Toggle Menu'
            className='menu-button'
            onClick={toggle}
            sx={{
              appearance: 'none',
              m: 0,
              p: 1,
              color: lighten(`primary`, 0.2),
              bg: 'transparent',
              ':focus': {
                outline: 0,
              },
              ':hover': {
                color: 'secondary',
              },
              svg: {
                display: 'block',
                margin: 0,
              }
            }}
          >
          {isShown === false
            ? <svg
                xmlns="http://www.w3.org/2000/svg"
                width='24'
                height='24'
                fill="currentcolor"
                viewBox="0 0 24 24"
              >
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
              </svg>
            : <svg
                xmlns="http://www.w3.org/2000/svg"
                width='24'
                height='24'
                fill="currentcolor"
                viewBox="0 0 40 40"
              >
                <path d="M28.696 29.698c-0.938 0.938-2.458 0.938-3.394 0l-5.302-6.060-5.302 6.058c-0.938 0.938-2.458 0.938-3.394 0-0.938-0.938-0.938-2.458 0-3.394l5.516-6.3-5.518-6.304c-0.938-0.938-0.938-2.456 0-3.394s2.456-0.938 3.394 0l5.304 6.062 5.302-6.062c0.938-0.938 2.456-0.938 3.394 0s0.938 2.458 0 3.394l-5.516 6.304 5.516 6.3c0.938 0.938 0.938 2.458 0 3.396z"></path>
              </svg>
          }
          </Button>
          {isShown &&
            <MainMenu page={isFrontPage} />
          }
        </Flex>
      }
      {isDesktopOrLaptop &&
        <MainMenuDesktop page={isFrontPage} />
      }
    </Box>
  )
}

export default Header